.small_wrapper {
  width: 720px;
  margin-left: auto;
  margin-right: auto;
}

.wrapper,
.wrapper_960 {
  width: 960px;
  margin-left: auto;
  margin-right: auto;
}

.big_wrapper,
.wrapper_1200 {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.wrapper_1265 {
  width: 1265px;
  margin-left: auto;
  margin-right: auto;
}

.contents {
  margin-top: calc-em(58px, 16px);
}

.contents,
.contents_without_margin {
  min-height: 500px;
}

.contents_without_margin {
  padding-top: 0px;
}

.section,
.yellow_section {
  margin: 40px 0;
  padding: 40px 0;
  width: 100%;
}

.yellow_section {
  /*
  background: none repeat scroll 0 0 $yellow;
  border-bottom: 1px solid $yellow_border;
  border-top: 1px solid $yellow_border;
  */
  background-color: $color-alabaster;
}

label.error {
  color: red;
  font-size: 12px;
  width: auto;
  display: block;
}

.dialog {
  background-color: white;

}

.ui-dialog {
  z-index: 1011;
}

.ui-dialog-titlebar {
  text-align: right;

  button {
    background: none;
    color: $green;
    margin: -10px -20px 0 0;
    padding: 0px;
    font-size: 28px;
    border: 0px;
    float: right;
  }
}

#bodyclick,
#bodyclick-unclickable {
  display: none;
  position: fixed;
  _position: absolute;
  /* hack for internet explorer 6*/
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  background: $black;
  cursor: pointer;
}

#bodyclick-unclickable {
  cursor: default;
}

#close-popup {
  position: fixed;
  //	top: 68px;
  top: 10px;
  right: 10px;
  text-align: center;
  color: white;
  opacity: 0.8;
  font-size: 35px;
  text-shadow: 0px 0px 3px #000;
  cursor: pointer;
  z-index: 999; //100;
  font-family: $montserrat !important;
}

#close-popup {
  display: none;
}

.vertical_margin_5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.vertical_margin_10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.vertical_margin_20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-40 {
  margin-top: 40px;
}

.underlined {
  text-decoration: underline;
}

.not_underlined {
  text-decoration: none;
}

.bold {
  font-weight: bold;
}

.line-through,
.line_through,
.canceled,
.annulled {
  text-decoration: line-through;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.clear {
  clear: both;
}

.hidden {
  display: none;
}

.valign_top {
  vertical-align: top;
}

.valign_center {
  vertical-align: center;
}

.valign_bottom {
  vertical-align: bottom;
}

.align_center,
.center_align,
.align-center,
.center-align,
.centered {
  text-align: center;
}

.align_left,
.left_align,
.left-align,
.align-left,
.lefted {
  text-align: left;
}

.align_right,
.right_align,
.right-align,
.align-right,
.righted {
  text-align: right;
}

.float_left {
  float: left;
}

.float_right {
  float: right;
}

.cursor-pointer,
.c-pointer {
  cursor: pointer;
}

.content-label {
  font-family: $lora;

  ul {
    list-style-type: disc;
    margin-left: 24px;

    li {
      margin-bottom: 6px;
    }
  }
}

.bar-separator {
  color: $grey;
  margin: 0 calc-em(8px, 16px) 0 calc-em(8px, 16px);
}

.page-container {
  margin-top: 48px;
  padding: 0 10%;
}

img.desaturate {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.very-transparent {
  opacity: 0.25;
}

.half-transparent {
  opacity: 0.50;
}

.little-bit-transparent {
  opacity: 0.75;
}


.svg-sprites {
  display: none;
}


.help-popup-title {
  color: $black;
  font-weight: 300;
}

.help-popup-text {
  display: inline-block;
  margin-right: 5%;
  vertical-align: top;
  width: 30%;
}

.help-popup-form {
  display: inline-block;
  vertical-align: top;
}

.video-placeholder {
  background: grey;
  height: 374px;
  width: 100%;
}

.video-placeholder-img {
  width: 100%;
}

#login-and-register-popup-container {
  display: none;
}

.lightbox-content {
  display: none;
}

#pricing-info-popup {
  max-width: 600px;
  line-height: 1.5rem;
  padding: 1.5rem;
}


.discount-code {
  background: $color-pastel-red;
  color: white;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}